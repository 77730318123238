.app-table{
    width: 100%;
    margin-top: 4%;
}

.app-overview-table-row td{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #707070;
    padding-bottom: 2%;
    padding-top: 2%;
}
.app-table tr th{
    width: 20%;
}

.app-table-icon{
    float: left;
    width: 20%;
    border-radius: 5px!important;
    margin-top: -2.5%!important;
}

.app-table-icon img{
    border-radius: 5px;
}

.app-table-firstrow{
    padding-top: 3.5%;
    width: 10%!important;
}


.app-table-title{
    margin-left: 6%;
    font-weight: 800;
    font-size: 15px;
    width: 100%;
    margin-top: 6%!important;
    padding-top: 3%;
}

.app-table-po{
    margin-left: 6%;
    font-weight: 400;
    font-size: 15px;
    width: 100%;
}

.app-table-row td{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #707070;
    padding-bottom: 4%;
    padding-top: 4%;
}
.head-block{
    width: 100%;
    position: absolute;
    z-index: 999;
    margin-top: -3%;
}

.MuiButton-root{
    text-transform: initial!important;
}

.add-button{
    background-color: #E74F28!important;
    color: white!important;
    border-radius: 50px!important;
    padding: 5% 16%!important;
    border-color: transparent!important;
    width: 100%;
}

.input-field-sets{
    background-color: white;
    padding: 3% 16%;
    width: 100%;
    right: 0;

    border-radius: 50px;
    border-style: solid;
    border-color: gray;
    border-width: 1px;
}

.right-svg-icon{
    float: right;
    width: 5%;
    margin-right: 10%;
    margin-top: 4%;
}
.list-expand-full-width{
    width: 100%;
    padding-bottom: 5%;
}

.small-settings-section{
    background-color: white;
    border-radius: 5px;
    padding-top: 2%;
    padding-bottom: 2%;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-style: solid;
    border-width: 1px;
    border-color: #70707038;
}

.small-settings-section li{
    list-style: none;
    padding: 2% 4% 3% 4%;
    border-bottom-style: solid;
    border-bottom-color:  #70707038;
    border-bottom-width: 1px;
}

.align-left{
    text-align: left;
    width: 30%;
}

.align-right{
    float: right;
    width: 70%;
}

.align-left-expand{
    width: 30%;
    float: left;
    margin-top: 1.5%;
}
.align-right-expand{
    width: 70%;
    float: right;
}

.align-right-expand input{
    width: 100%;
    padding: 1%;

  
    
}

.category-item{
    text-align: left;
    width: 70%;
}

.align-right-actions{
    float: right;
    width: 30%;
}

.arrow-up{
    transform: rotate(180deg);
}

.align-right input{
    width: 100%;
    padding: 1%;
    margin-top: -1%;
}

.settings-div{
    padding: 5% 4%;
}

.message{
    text-align: left!important;
    float: left;
    width: 100%;
    margin-top: 2%;
    color: red;
}

.marginBottom{
    margin-bottom: 5%!important;
}

.recover-button{
    float: left!important;
    padding: 1.5% 6%!important;
    background-color: transparent!important;
    border-style: solid!important;
    border-color: #E74F28!important;
    border-width: 2px!important;
    border-radius: 50px!important;
    margin-top: -1.5%!important;
    color: #E74F28!important;
    font-size: 15px!important;
    width: 25%;
}

.recover-button:hover{
    background-color: #a73013!important;
    color: white!important;
}

.delete{
    border-color: red!important;
    border-width: 2px!important;
    border-radius: 50px!important;
    margin-top: -1.5%!important;
    color: red!important;
}

.delete:hover{
    background-color: red!important;
    color: white!important;
}

.save-button{
    float: right!important;
    padding: 1.5% 6%!important;
    background-color: #E74F28!important;
    border-radius: 50px!important;
    margin-top: -1.5%!important;
    color: white!important;
    font-size: 15px!important;
    border-style: none!important;
    width: 25%;
}

.save-button:hover{
    background-color:  #9b5341!important;
    color: white!important;
}

.margin-top{
    margin-top: 4%;
    padding-bottom: 3%;
}

.margin-top h4{
    margin-bottom: 4%;

}

.svg-icon-actions{
    width: 3%!important;
    margin-right: 4%;
    float: right;
}

.textarea textarea{
    width: 100%;
    margin-top: 2%;
    padding: 1%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    float: right;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .edit-screen-detail{
    width: 80%;
    margin-left: 10%;   
    border: solid 1px black;
    border-radius: 5px;
    padding: 5%;
    padding-bottom: 15%;
    background-color: white;
  }

  .edit-screen-detail li{
    list-style: none;
    width: 100%;
    margin-bottom: 3%;
    margin-top: 1%;
  }

  .marginTop .post-it-update,   .marginTop .post-it-close{
      padding-top: 6.5%!important;
      margin-right: 4%!important;
  }

  .marginTop button{
      margin-top: 5%!important;
  }

  .reset-button{
        background-color: #3792cb!important;
        border-color: #3792cb!important;
        float: right;
        margin-top: -2%!important;
        margin-right: 2%!important;
  }

  .delete-button{
    background-color: red!important;
    border-color: red!important;
    float: right;
    margin-top: -2%!important;
    
  }